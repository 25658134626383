<template>
  <section>
    <p class="text-h6 secondary--text">Tarjetas</p>

    <!-- Alinear contenido a la derecha -->
    <v-row class="d-flex justify-end">
      <v-col cols="3">
        <v-btn
          block
          color="primary"
          @click="
            agregarDialog = true;
            id_tarjeta = null;
          "
          >Agregar tarjeta</v-btn
        >
      </v-col>
    </v-row>

    <AppDialog
      v-model="agregarDialog"
      title="Agregar tarjeta"
      :max-width="800"
      classButtons="d-flex justify-end"
    >
      <template #content>
        <AgregarTarjetaComponent
          :id_tarjeta="id_tarjeta"
          @cancelar-registro="agregarDialog = false"
          @registrar-tarjeta="
            getTarjetas();
            agregarDialog = false;
          "
        />
      </template>
    </AppDialog>

    <!-- Tabla con la información de las tarjetas -->
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="tarjetas"
          class="elevation-1"
          hide-default-footer
        >
          <template v-slot:[`item.accion`]="{ item }">
            <v-btn icon>
              <v-icon
                @click="
                  id_tarjeta = item.id;
                  agregarDialog = true;
                "
                >mdi-pencil</v-icon
              >
            </v-btn>
            <v-btn
              @click="
                id_tarjeta = item.id;
                borrarDialog = true;
              "
              icon
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <!-- Cuadro de confirmación para borrar un registro -->
    <ConfirmationDialogComponent
      :show="borrarDialog"
      btnConfirmar="Aceptar"
      title="¿Está seguro de eliminar la tarjeta"
      message="De confirmarse la siguiente acción, los cambios no serán reversibles."
      @close="borrarDialog = false"
      @confirm="borrarTarjeta"
    />
  </section>
</template>
<script>
import AppDialog from "../../components/AppDialog.vue";
import AgregarTarjetaComponent from "./components/AgregarTarjetaComponent.vue";
import ConfirmationDialogComponent from "../../components/ConfirmationDialogComponent.vue";

export default {
  components: {
    AgregarTarjetaComponent,
    AppDialog,
    ConfirmationDialogComponent,
  },
  data: () => ({
    agregarDialog: false,
    borrarDialog: false,
    id_tarjeta: null,
    tarjetas: [],
    headers: [
      { text: "Nombre del titular", value: "nombre_titular" },
      { text: "Tipo de tarjeta", value: "tipo_tarjeta" },
      { text: "Marca", value: "marca_internacional" },
      { text: "Emisora", value: "institucion_financiera" },
      { text: "Institución", value: "institucion" },
      { text: "Últimos 4 dígitos", value: "ultimos_cuatro_digitos" },
      { text: "Acciones", value: "accion" },
    ],
  }),
  methods: {
    async getTarjetas() {
      const response = await this.services.Tarjetas.getTarjetas();
      if (response.status === 200) {
        this.tarjetas = response.data;
      }
    },
    async borrarTarjeta() {
      const response = await this.services.Tarjetas.borrarTarjeta(
        this.id_tarjeta
      );
      if (response.status === 200) {
        this.temporalAlert({
          show: true,
          message: response.data.message,
          type: "success",
        });

        this.borrarDialog = false;

        this.getTarjetas();
      }
    },
  },
  mounted() {
    this.getTarjetas();
  },
};
</script>
<style lang=""></style>
