<template>
  <div class="text-center">
    <!-- Formulario para registrar tarjetas de débito/crédito -->
    <v-form>
      <v-row>
        <v-col cols="4">
          <v-select
            :items="tiposTarjetas"
            label="Tipo de tarjeta"
            item-text="nombre"
            item-value="id"
            outlined
            required
            v-model="formulario.id_tipo_tarjeta"
            :error-messages="tipoTarjetaErrors"
          >
          </v-select>
        </v-col>
        <v-col cols="4">
          <v-select
            :items="tiposMarcasTarjetas"
            label="Marca de tarjeta"
            item-text="nombre"
            item-value="id"
            outlined
            required
            v-model="formulario.id_marca_internacional"
            :error-messages="marcaTarjetaErrors"
          >
          </v-select>
        </v-col>
        <v-col cols="4">
          <v-select
            :items="tiposInstituciones"
            label="Institución financiera"
            item-text="nombre"
            item-value="id"
            outlined
            required
            v-model="formulario.id_institucion_financiera"
            :error-messages="institucionFinancieraErrors"
          >
          </v-select>
        </v-col>

        <v-col cols="12">
          <!-- Nombre de titular -->
          <v-text-field
            label="Nombre del titular"
            outlined
            required
            v-model="formulario.nombre_titular"
            :error-messages="nombreTitularErrors"
          >
          </v-text-field>
        </v-col>

        <v-col cols="6">
          <v-text-field
            label="Número de tarjeta (ultimo 4 dígitos)"
            outlined
            required
            placeholder="####"
            v-mask="'####'"
            v-model="formulario.ultimos_cuatro_digitos"
            :error-messages="ultimosCuatroDigitosErrors"
          >
          </v-text-field>
        </v-col>

        <v-col cols="6">
          <v-text-field
            label="Fecha de vencimiento"
            outlined
            required
            placeholder="MM/AA"
            v-mask="'##/##'"
            v-model="formulario.fecha_vencimiento"
            :error-messages="fechaVencimientoErrors"
          >
          </v-text-field>
        </v-col>
      </v-row>

      <!-- Botón de guardar y cancelar -->
      <v-row class="d-flex justify-space-around">
        <v-col cols="3">
          <v-btn block outlined @click="$emit('cancelar-registro')">
            Cancelar
          </v-btn>
        </v-col>
        <v-col cols="3">
          <v-btn
            block
            color="primary"
            class="mr-4"
            :loading="loading"
            @click="registrarTarjeta"
          >
            Guardar
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>
import { required, maxLength, minLength } from "vuelidate/lib/validators";
export default {
  name: "AgregarTarjetaComponent",
  props: {
    id_tarjeta: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      tiposTarjetas: [],
      tiposMarcasTarjetas: [],
      tiposInstituciones: [],
      formulario: {
        id_tipo_tarjeta: null,
        id_marca_internacional: null,
        id_institucion_financiera: null,
        ultimos_cuatro_digitos: null,
        fecha_vencimiento: null,
        nombre_titular: null,
      },
    };
  },
  validations: {
    formulario: {
      id_tipo_tarjeta: { required },
      id_marca_internacional: { required },
      id_institucion_financiera: { required },
      ultimos_cuatro_digitos: {
        required,
        minLength: minLength(4),
        maxLength: maxLength(4),
      },
      fecha_vencimiento: {
        required,
        minLength: minLength(5),
        maxLength: maxLength(5),
      },
      nombre_titular: { required },
    },
  },
  methods: {
    async getTiposTarjetas() {
      const response = await this.services.Tarjetas.getTiposTarjetas();
      if (response.status === 200) {
        this.tiposTarjetas = response.data;
      }
    },
    async getTiposMarcasTarjetas() {
      const response = await this.services.Tarjetas.getTiposMarcasTarjetas();
      if (response.status === 200) {
        this.tiposMarcasTarjetas = response.data;
      }
    },
    async getTiposInstituciones() {
      const response =
        await this.services.Tarjetas.getInstitucionesFinancierasTarjetas();
      if (response.status === 200) {
        this.tiposInstituciones = response.data;
      }
    },
    async getTarjeta() {
      this.loading = true;
      const response = await this.services.Tarjetas.getTarjeta(this.id_tarjeta);
      if (response.status === 200) {
        this.formulario = response.data;
      }
      this.loading = false;
    },
    async registrarTarjeta() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.loading = true;
        let response = null;

        if (this.id_tarjeta) {
          response = await this.services.Tarjetas.putTarjetas(
            this.id_tarjeta,
            this.formulario
          );
        } else {
          response = await this.services.Tarjetas.postTarjetas(this.formulario);
        }

        if (response.status === 201 || response.status === 200) {
          this.temporalAlert({
            show: true,
            message: response.data.message,
            type: "success",
          });
          // Reinicio de validaciones
          this.$v.$reset();

          // Emitimos el evento para actualizar la tabla
          this.$emit("registrar-tarjeta", response.data);
        }
        this.loading = false;
      }
    },
  },
  computed: {
    // Variables para validar el formulario
    tipoTarjetaErrors() {
      const errors = [];
      if (!this.$v.formulario.id_tipo_tarjeta.$dirty) return errors;
      !this.$v.formulario.id_tipo_tarjeta.required &&
        errors.push("El tipo de tarjeta es requerido");
      return errors;
    },
    marcaTarjetaErrors() {
      const errors = [];
      if (!this.$v.formulario.id_marca_internacional.$dirty) return errors;
      !this.$v.formulario.id_marca_internacional.required &&
        errors.push("La marca de tarjeta es requerida");
      return errors;
    },
    institucionFinancieraErrors() {
      const errors = [];
      if (!this.$v.formulario.id_institucion_financiera.$dirty) return errors;
      !this.$v.formulario.id_institucion_financiera.required &&
        errors.push("La institución financiera es requerida");
      return errors;
    },
    ultimosCuatroDigitosErrors() {
      const errors = [];
      if (!this.$v.formulario.ultimos_cuatro_digitos.$dirty) return errors;
      !this.$v.formulario.ultimos_cuatro_digitos.required &&
        errors.push("Los últimos 4 dígitos son requeridos");
      !this.$v.formulario.ultimos_cuatro_digitos.minLength &&
        errors.push("Los últimos 4 dígitos deben ser 4 dígitos");
      !this.$v.formulario.ultimos_cuatro_digitos.maxLength &&
        errors.push("Los últimos 4 dígitos deben ser 4 dígitos");
      return errors;
    },
    fechaVencimientoErrors() {
      const errors = [];
      if (!this.$v.formulario.fecha_vencimiento.$dirty) return errors;
      !this.$v.formulario.fecha_vencimiento.required &&
        errors.push("La fecha de vencimiento es requerida");
      !this.$v.formulario.fecha_vencimiento.minLength &&
        errors.push("La fecha de vencimiento debe ser MM/AA");
      !this.$v.formulario.fecha_vencimiento.maxLength &&
        errors.push("La fecha de vencimiento debe ser MM/AA");
      return errors;
    },
    nombreTitularErrors() {
      const errors = [];
      if (!this.$v.formulario.nombre_titular.$dirty) return errors;
      !this.$v.formulario.nombre_titular.required &&
        errors.push("El nombre del titular es requerido");
      return errors;
    },
  },
  // Watch de la prop id_tarjeta si es diferente de null obtiene la información de la tarjeta
  watch: {
    id_tarjeta: {
      handler: function (val) {
        // Reinicio de validaciones y formulario
        this.$v.$reset();
        this.formulario = {
          id_tipo_tarjeta: null,
          id_marca_internacional: null,
          id_institucion_financiera: null,
          ultimos_cuatro_digitos: null,
          fecha_vencimiento: null,
          nombre_titular: null,
        };
        if (val !== null) {
          this.getTarjeta();
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.getTiposTarjetas();
    this.getTiposMarcasTarjetas();
    this.getTiposInstituciones();
  },
};
</script>
<style lang=""></style>
